<template>
  <div class="dashboard">
    <div class="page-header">
      <h2>首页</h2>
    </div>
    
    <el-card class="welcome-card">
      <div class="welcome-content">
        <h3>欢迎使用剧星达人系统</h3>
        <p>请从左侧菜单选择功能模块开始使用</p>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {
      // 首页数据
    }
  },
  created() {
    // 首页初始化
  },
  methods: {
    // 首页方法
  }
}
</script>

<style scoped>
.dashboard {
  padding: 20px;
}

.page-header {
  margin-bottom: 20px;
}

.welcome-card {
  margin-bottom: 20px;
}

.welcome-content {
  text-align: center;
  padding: 40px 0;
}

.welcome-content h3 {
  font-size: 24px;
  color: #303133;
  margin-bottom: 20px;
}

.welcome-content p {
  font-size: 16px;
  color: #606266;
}
</style> 